



























































import { SfSidebar } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import CartList from '~/modules/fortytwo/cart/components/CartList.vue';
import CartTotal from '~/modules/fortytwo/cart/components/CartTotal.vue';

export default defineComponent({
  name: 'FortytwoCartSidebar',
  components: {
    SfSidebar,
    CartList,
    CartTotal,
  },
  setup() {
    const cartView = useCartView();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();

    return {
      ...cartView,
      isCartSidebarOpen,
      toggleCartSidebar,
    };
  },
});
